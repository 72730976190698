import React from "react"
import Layout from "../../components/layout/layout"

import Seo from "../../components/layout/seo";

export default function Agb() {
  return (
    <Layout showBottom="false">
      <Seo title="Drops | AGBs | Toothpaste Drops" path="/pages/agb" description="Über 5000 zufriedene Kunden - Natürlich - Plastikfrei - Ohne Tierversuche - Vegan - Zahnpasta Drops. Hilf uns den Plastikmüll in unserer Umwelt & den Meeren zu beenden. #weltretter" />
      <div style={{ minHeight: 'calc(100vh - 355px)' }}>
        <div style={{ paddingTop: '100px'}} className="container mb-5">
          <h1>Allgemeine Geschäftsbedingungen</h1>
          <div className="title-condition">
            <p><strong>Allgemeine Gesch&auml;ftsbedingungen</strong></p>
            <h2>1.&nbsp;Geltungsbereich</h2>
            <p>Für alle Bestellungen über unseren Online-Shop gelten die nachfolgenden AGB. Unser Online-Shop richtet sich ausschließlich an Verbraucher.</p>
            <p>Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.<br /></p>
            <h2>2.&nbsp;Vertragspartner, Vertragsschluss, Korrekturm&ouml;glichkeiten</h2>
            <p>Der Kaufvertrag kommt zustande mit Routinery GmbH.</p>
            <p>Mit Einstellung der Produkte in den Online-Shop geben wir ein verbindliches Angebot zum Vertragsschluss über diese Artikel ab. Sie können unsere Produkte zunächst unverbindlich in den Warenkorb legen und Ihre Eingaben vor Absenden Ihrer verbindlichen Bestellung jederzeit korrigieren, indem Sie die hierfür im Bestellablauf vorgesehenen und erläuterten Korrekturhilfen nutzen. Der Vertrag kommt zustande, indem Sie durch Anklicken des Bestellbuttons das Angebot über die im Warenkorb enthaltenen Waren annehmen. Unmittelbar nach dem Absenden der Bestellung erhalten Sie noch einmal eine Bestätigung per E-Mail.<br /></p>
            <h2>3.&nbsp;Vertragssprache, Vertragstextspeicherung</h2>
            <p>Die f&uuml;r den Vertragsschluss zur Verf&uuml;gung stehende(n) Sprache(n): Deutsch, Englisch</p>
            <p>Wir speichern den Vertragstext und senden Ihnen die Bestelldaten und unsere AGB in Textform zu. Den Vertragstext können Sie in unserem Kunden-Login einsehen.<br /></p>
            <h2>4.&nbsp;Lieferbedingungen</h2>
            <p>Zuz&uuml;glich zu den angegebenen Produktpreisen k&ouml;nnen noch Versandkosten anfallen. N&auml;here Bestimmungen zu ggf. anfallenden Versandkosten erfahren Sie bei den Angeboten.</p>
            <p>Wir liefern nur im Versandweg. Eine Selbstabholung der Ware ist leider nicht m&ouml;glich. </p>
            <h2>5.&nbsp;Bezahlung</h2>
            <p>In unserem Shop stehen Ihnen grunds&auml;tzlich die folgenden Zahlungsarten zur Verf&uuml;gung:</p>
            <p><strong>Kreditkarte</strong><br /> Mit Abgabe der Bestellung geben Sie Ihre Kreditkartendaten an. Ihre Karte wird unmittelbar nach Abgabe der Bestellung belastet.</p>
            <p><strong>PayPal, PayPal Express</strong><br /> Um den Rechnungsbetrag über den Zahlungsdienstleister PayPal (Europe) S.à r.l. et Cie, S.C.A, 22-24 Boulevard Royal, L-2449 Luxembourg („PayPal“) bezahlen zu können, müssen Sie bei PayPal registriert sein, sich mit Ihren Zugangsdaten legitimieren und die Zahlungsanweisung bestätigen.   Die Zahlungstransaktion wird durch PayPal unmittelbar nach Abgabe der Bestellung durchgeführt. Weitere Hinweise erhalten Sie im Bestellvorgang.</p>
            <p><strong>Sofort by klarna</strong><br /> Um den Rechnungsbetrag &uuml;ber den Zahlungsdienstleister Sofort GmbH, Theresienh&ouml;he 12, 80339 M&uuml;nchen bezahlen zu k&ouml;nnen, m&uuml;ssen Sie &uuml;ber ein f&uuml;r Online-Banking freigeschaltetes Bankkonto verf&uuml;gen, sich entsprechend legitimieren und die Zahlungsanweisung best&auml;tigen. Ihr Konto wird unmittelbar nach Abgabe der Bestellung belastet. Weitere Hinweise erhalten Sie im Bestellvorgang.</p>
            <p><strong>Google Pay</strong><br /> Um den Rechnungsbetrag &uuml;ber den Zahlungsdienstleister Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Irland (&bdquo;Google&ldquo;) bezahlen zu k&ouml;nnen, m&uuml;ssen Sie bei Google registriert sein, die Funktion Google Pay aktiviert haben, sich mit Ihren Zugangsdaten legitimieren und die Zahlungsanweisung best&auml;tigen. Die Zahlungstransaktion wird unmittelbar nach Abgabe der Bestellung durchgef&uuml;hrt. Weitere Hinweise erhalten Sie im Bestellvorgang.</p>
            <p><strong>Apple Pay</strong><br /> Um den Rechnungsbetrag über den Zahlungsdienstleister Apple Inc., One Apple Park Way, Cupertino, CA 95014, USA („Apple“) bezahlen zu können, müssen Sie den Browser „Safari“ nutzen, bei Apple registriert sein, die Funktion Apple Pay aktiviert haben, sich mit Ihren Zugangsdaten legitimieren und die Zahlungsanweisung bestätigen. Die Zahlungstransaktion wird unmittelbar nach Abgabe der Bestellung durchgeführt. Weitere Hinweise erhalten Sie im Bestellvorgang.</p>
            <p><strong>Klarna</strong><br /> In Zusammenarbeit mit dem Zahlungsdienstleister Klarna Bank AB (publ.), Sveavägen 46, 111 34 Stockholm, Schweden („Klarna“) bieten wir Ihnen die nachfolgenden Zahlungsoptionen an. Die Zahlung über Klarna ist nur für Verbraucher verfügbar. Sofern nachfolgend nichts anderes geregelt ist, setzt die Zahlung über Klarna eine erfolgreiche Adress- und Bonitätsprüfung voraus und sie erfolgt direkt an Klarna. Weitere Hinweise erhalten Sie bei der jeweiligen Zahlungsoption und im Bestellprozess.</p>
            <p style={{marginLeft:'40px'}}><strong>Kauf auf Rechnung &uuml;ber Klarna</strong><br /> Der Rechnungsbetrag ist 14 Tage nach Versand der Ware und Erhalt der Rechnung f&auml;llig.</p>
            <p style={{marginLeft:'40px'}}><strong>Ratenkauf über Klarna</strong><br /> Sie können den Rechnungsbetrag in monatlichen Raten von mindestens 1/24 des Gesamtbetrages bezahlen. Die Höhe der Mindestrate beträgt 6,95 Euro.</p>
            <h2>6.&nbsp;Transportsch&auml;den​​​​​​​</h2>
            <p>Werden Waren mit offensichtlichen Transportschäden angeliefert, so reklamieren Sie solche Fehler bitte möglichst sofort beim Zusteller und nehmen Sie bitte unverzüglich Kontakt zu uns auf. Die Versäumung einer Reklamation oder Kontaktaufnahme hat für Ihre gesetzlichen Ansprüche und deren Durchsetzung, insbesondere Ihre Gewährleistungsrechte, keinerlei Konsequenzen. Sie helfen uns aber, unsere eigenen Ansprüche gegenüber dem Frachtführer bzw. der Transportversicherung geltend machen zu können.<br /></p>
            <h2>7.&nbsp;Gewährleistung und Garantien​​​​​​​</h2>
            <p><strong>Geltung des gesetzlichen Mängelhaftungsrechts</strong></p>
            <p>Soweit nicht nachstehend ausdrücklich anders vereinbart, gilt das gesetzliche Mängelhaftungsrecht.<br /> Die nachstehenden Einschränkungen und Fristverkürzungen gelten nicht für Ansprüche aufgrund von Schäden, die durch uns, unsere gesetzlichen Vertreter oder Erfüllungsgehilfen verursacht wurden</p>
            <ul>
               <li>bei Verletzung des Lebens, des Körpers oder der Gesundheit</li>
               <li>bei vorsätzlicher oder grob fahrlässiger Pflichtverletzung sowie Arglist</li>
               <li>bei Verletzung wesentlicher Vertragspflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf (Kardinalpflichten)</li>
               <li>im Rahmen eines Garantieversprechens, soweit vereinbart, oder</li>
               <li>soweit der Anwendungsbereich des Produkthaftungsgesetzes eröffnet ist.</li>
            </ul>
            <p><strong>Beschränkungen gegenüber Verbrauchern</strong></p>
            <p>Beim Kauf gebrauchter Waren durch Verbraucher gilt: wenn der Mangel nach Ablauf eines Jahres ab Ablieferung der Ware auftritt, sind die Mängelansprüche ausgeschlossen. Mängel, die innerhalb eines Jahres ab Ablieferung der Ware auftreten, können im Rahmen der gesetzlichen Verjährungsfrist von zwei Jahren ab Ablieferung der Ware geltend gemacht werden.</p>
            <p><strong>Garantien und Kundendienst</strong></p>
            <p>Informationen zu gegebenenfalls geltenden zusätzlichen Garantien und deren genaue Bedingungen finden Sie jeweils beim Produkt und auf besonderen Informationsseiten im Online-Shop.</p>
            <p>Kundendienst: Sie erreichen unseren Kundendienst für Fragen, Reklamationen und Beanstandungen werktags von 9:00 Uhr bis 18:00 Uhr unter der Telefonnummer 030-93683498 sowie per E-Mail unter info@routinery.de</p>
            <h2>8.&nbsp;Haftung​​​​​​​</h2>
            <p>F&uuml;r Anspr&uuml;che aufgrund von Sch&auml;den, die durch uns, unsere gesetzlichen Vertreter oder Erf&uuml;llungsgehilfen verursacht wurden, haften wir stets unbeschr&auml;nkt</p>
            <ul>
               <li>bei Verletzung des Lebens, des K&ouml;rpers oder der Gesundheit,</li>
               <li>bei vors&auml;tzlicher oder grob fahrl&auml;ssiger Pflichtverletzung,</li>
               <li>bei Garantieversprechen, soweit vereinbart, oder</li>
               <li>soweit der Anwendungsbereich des Produkthaftungsgesetzes er&ouml;ffnet ist.</li>
            </ul>
            <p>Bei Verletzung wesentlicher Vertragspflichten, deren Erf&uuml;llung die ordnungsgem&auml;&szlig;e Durchf&uuml;hrung des Vertrages &uuml;berhaupt erst erm&ouml;glicht und auf deren Einhaltung der Vertragspartner regelm&auml;&szlig;ig vertrauen darf, (Kardinalpflichten) durch leichte Fahrl&auml;ssigkeit von uns, unseren gesetzlichen Vertretern oder Erf&uuml;llungsgehilfen ist die Haftung der H&ouml;he nach auf den bei Vertragsschluss vorhersehbaren Schaden begrenzt, mit dessen Entstehung typischerweise gerechnet werden muss. <br /> Im &Uuml;brigen sind Anspr&uuml;che auf Schadensersatz ausgeschlossen.</p>
            <h2>9.&nbsp;Streitbeilegung​​​​​​​</h2>
            <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer">hier</a> finden. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.<br /> <br /></p>
            <p><i><a target="_blank" rel="noreferrer" title="AGB" href="https://legal.trustedshops.com/produkte/rechtstexter">AGB</a> erstellt mit dem <a target="_blank" rel="noreferrer" title="Trusted Shops" href="https://legal.trustedshops.com/">Trusted Shops</a> Rechtstexter in Kooperation mit <a target="_blank" title="FÖHLISCH Rechtsanwälte" href="https://foehlisch.com" rel="noreferrer nofollow">FÖHLISCH Rechtsanwälte</a>.</i></p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
